@import 'styles/utils';

.sectionTitle.sectionTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.dateField.dateField {
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  input[type='date']:invalid,
  input[type='date'][value=''] {
    font-weight: 400;
    color: inherit;
  }
}

.hint {
  display: flex;
  align-items: flex-start;
  .hintIcon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin-right: 10px;
    color: inherit;
  }
}

.reservationInformationSectionWrapper {
  background-color: $light-Grey;
  border-radius: 4px;
  padding: 20px 0;
  position: relative;
  .image {
    height: auto;
    width: 100%;
    object-fit: cover;
    display: block;
    max-height: 150px;
    padding: 0 15px;
  }
  .vehicleDescription {
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 5px;
    color: $grey-80;
  }
  .basePrice {
    font-size: 14px;
    color: $grey-60;
    margin-bottom: 3px;
    line-height: 20px;
  }
  .price {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: $black;
  }
}

.subHeader.subHeader {
  font-weight: 700;
  line-height: 24px;
  color: $black;
  padding: 6px 16px;
  font-size: 16px;
}

.otherCountries.otherCountries {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.group.group {
  color: $grey-90;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
}

.type.type {
  color: $grey-60;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
}

.carInformation.carInformation {
  display: flex;
  flex-direction: column;
  @include breakpoint('xs') {
    flex-direction: row;
  }
  & > p {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
}

.vehicleBox.vehicleBox {
  margin-bottom: 10px;
}

.vehicleLabel.vehicleLabel {
  color: $grey-90;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
}

.vehicleHint.vehicleHint {
  margin-top: 5px;
}
