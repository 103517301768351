@import 'styles/utils';

.inputStationBox {
  height: fit-content;
  width: 30%;

  @include breakpoint('xl') {
    width: 33%;
  }

  @include breakpoint('xxl') {
    width: 40%;
  }
}

.inputOneWayStationBox {
  height: fit-content;
  width: 15%;

  @include breakpoint('xl') {
    width: 20%;
  }

  @include breakpoint('xxl') {
    width: 25%;
  }
}

.oneWayDistanceBox {
  height: 24px;
  margin-top: 4px;
  line-height: 24px;
  text-wrap: nowrap;
  color: $mid-Grey;
}

.anchorLink {
  position: relative;
  span {
    position: absolute;
    top: -65px;
  }
}
