@import 'styles/utils';

.footerMobileWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  column-gap: 15px;
  padding-bottom: 13px;
  @include breakpoint('xs') {
    column-gap: 8px;
  }
}

.footerMobileLinkBox {
  width: calc(50% - 7.5px);
  @include breakpoint('xs') {
    width: calc(50% - 4px);
  }
  p {
    padding-bottom: 5px;
    line-height: 1.5;
    :last-of-type {
      padding-bottom: 0;
    }
  }
}

.footerListWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 60px;
  @include breakpoint('md') {
    padding-bottom: 50px;
  }
  @include breakpoint('lg') {
    padding-bottom: 100px;
  }
}

.footerLinkStyles {
  padding-bottom: 5px;
  line-height: 1.5;
  width: fit-content;
  &:last-of-type {
    padding-bottom: 0;
  }
  &:hover {
    @include color-opacity;
  }
}

.footerLinkStyles a {
  text-decoration: none;
  color: inherit;
}

.footerListLinkWrapper p {
  padding-bottom: 5px;
  :last-of-type {
    padding-bottom: 0;
  }
}

.footerCardsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  @include breakpoint('lg') {
    padding-top: 100px;
  }
}

.card {
  background-color: $grey-transparent-03;
  width: 49%;
  @include breakpoint('lg') {
    width: 32%;
  }
}

.cardContentWrapper {
  padding: 0 15px 20px 15px;
  @include breakpoint('md') {
    padding: 0 17.5px 20px 17.5px;
  }
  @include breakpoint('xxl') {
    padding: 0 20px 20px 20px;
  }
  .text.text {
    & p {
      padding-bottom: 0;
      line-height: 24px;
    }
  }
}

.accordionDetails.accordionDetails {
  padding: 0 0 20px 0;
}

.anchorLink {
  position: relative;
  span {
    position: absolute;
    top: -65px;
  }
}
