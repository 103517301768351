.dateField.dateField {
  input[type='date'] {
    text-transform: uppercase;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type='date']:invalid,
  input[type='date'][value=''] {
    font-weight: 400;
  }

  @supports not (-webkit-touch-callout: none) {
    input[data-empty='noValue']:before {
      content: '';
    }
  }

  input[type='date']::-webkit-date-and-time-value {
    text-align: left;
  }
}
