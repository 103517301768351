@import 'styles/utils';

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionTitle.sectionTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
