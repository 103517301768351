@import 'styles/utils';

.container {
  position: fixed;
  right: 0;

  cursor: pointer;
  z-index: 101;
  bottom: 96px;
  margin-right: 21px;
  overflow: hidden;

  @include breakpoint('md') {
    width: auto;
    margin-right: 20px;

    &:hover .highlightText {
      transform: translateX(0);
      display: block;
    }

    &:hover .highlightTextWrapper {
      max-width:165px;
    }
  }
}
.qrImage {
  display: none;
  @include breakpoint('md') {
    display: block;
  }
}

.highlightText {
  margin-right: 0;
  color: $black;
  background-color: $white;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  transform: translateX(100%);
  font-size: 14.5px;
  width: 165px;
  overflow: hidden;
  height: auto;
  transition: max-width 0.5s ease, transform 0.5s ease;
  padding: 10px 15px 10px 15px;

  &.showHighlightText {
    transform: translateX(0);
  }
}

.highlightTextWrapper {
  transition: max-width 0.5s ease-out;
  max-width: 0;

  &.showHighlightText {
    max-width: 165px;
  }
  margin-right: -15px;
  overflow: hidden; 
}

.button.button {
  background-color: $starcar-yellow-dark-shadow;
  border-radius: 1rem;
  height: 56px;
  width: 56px;
  min-width: unset;

  border: 0;
  padding: 0;
  opacity: 1;
  display: block;

  &:focus {
    outline: none;
  }
}

.icon.icon {
  height: 35px;
  width: 35px;
  color: $white;
  margin-top: 5px;
}
.feedbackIcon {
  width: 65px;
  height: 65px;
  cursor: pointer;
  &:hover, &.active {
    width: 85px;
    height:85px;
  }
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 85px;
  background: inherit;
  border: inherit;
  padding: inherit; 
}