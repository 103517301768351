@import 'styles/utils';

.modalContainer {
  position: absolute;
  top: 50%;
  border: calc(vw - dvw) solid black;
  left: calc(50% - var(--scrollbar-width) / 2);
  transform: translate(-50%, -50%);
  border: none;
  background-color: $white;
  width: 94%;
  max-width: calc(1410px * 0.94);
  // max-height: calc(100dvh - 80px);
  --container-height: calc(100dvh - 100px);
  height: var(--container-height);
  border-radius: 4px;
  min-height: min(50dvh, 600px);
  // overflow-y: scroll;

  @include breakpoint('md') {
    // max-height: (80dvh);
    height: unset;
    background-size: max(30%, 350px);
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }
  @include breakpoint('xl') {
    width: 94%;
  }
  &:focus-visible,
  &:focus {
    outline: none 0;
  }
  min-height: 500px;
  // padding: 20px;
  *[role='presentation'] {
    z-index: 1306 !important;
  }
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  text-align: left;
  gap: 10px;
  p {
    font-weight: bold;
  }
  svg {
    margin-right: 5px;
  }
}
.closingIconWrapper {
  position: absolute;
  right: 0;
  top: -30px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  padding: 0;
  display: grid;
  place-content: center;
  &:hover {
    cursor: pointer;
  }
  color: $white;
  z-index: 1;
  border: none;
  svg {
    height: 100%;
    width: 100%;
  }
}
.headerWrapper {
  margin-bottom: 20px;
}
.title {
  max-width: calc(100% - 30px);
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 10px;
  @include breakpoint('md') {
    font-weight: 900;
    font-size: 2.625rem;
    line-height: normal;
    margin-bottom: 20px;
  }
}
.subTitle {
  line-height: 1.5rem;
}

.isHidden {
  @include breakpoint('md') {
    display: block;
  }
  display: none;
}
.displayNone.displayNone {
  display: none;
}
.mobileHeaderWrapper {
  width: 100%;
  border-bottom: 1px solid $border-Grey;
  padding-bottom: 10px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: flex-start;
  .mobileHeaderTitle {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    b {
      margin-left: 10px;
    }
    border-left: 1px solid $border-Grey;
    padding-left: 25px;
  }
  .backButton {
    border: none;
    height: 30px;
    background: unset;
    &:hover {
      cursor: pointer;
    }
  }
}
.variableWrapper {
  --button-width: 200px;
  --button-height: 40px;
}
.openButtonWrapper {
  z-index: 1100;
  position: fixed; // Position it relative to the viewport
  top: 40%; // Center vertically
  right: var(--button-height);
  &:hover {
    cursor: pointer;
  }

  transform-origin: top right;
  transform: rotate(270deg); //
  .yellow {
    background-color: $starcar-yellow;
  }
  .black {
    background-color: $black;
    color: $white;
  }
  &.top {
    top: calc(40% - (10px + (var(--button-width) / 2)));
  }
  &.bottom {
    top: calc(40% + (10px + (var(--button-width) / 2)));
  }
  &.scrollbarAdjustOnOpen {
    right: calc(var(--button-height) + var(--scrollbar-width));
  }
  display: none;
  @include breakpoint('md') {
    display: flex;
  }

  justify-content: center;
  align-items: center;
  justify-items: center;
  .openButtonText {
    font-weight: bold;
  }
}

.backgroundImageWrapper {
  width: 300px;
  height: auto;
  position: sticky;
  display: none;
  bottom: -20px;
  margin-bottom: -20px;
  left: 100%;
  @include breakpoint('md') {
    display: block;

    // margin-bottom: -125px;
  }
}
.backgroundImage {
  width: 300px;
  height: auto;
  position: absolute;
  display: none;
  margin-top: -300px;
  @include breakpoint('md') {
    display: inline-block;

    // margin-bottom: -125px;
  }
}
.openButton {
  border-radius: 4px 4px 0 0;
  width: var(--button-width);
  height: var(--button-height);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.3);
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
  svg {
    margin-left: -4px;
    padding-top: 5px;
  }
}

.contactOptionsContentWrapper {
  padding: 0;
}

.mobileMenuContainer {
  --mobile-menu-height: 260px;
  position: absolute;
  bottom: 0; //calc(var(--mobile-menu-height) * -0.5);
  left: 50%;
  transform: translate(-50%, 0);
  border: none;
  background-color: $white;
  width: 94%;
  max-width: calc(1410px * 0.94);
  border-radius: 4px 4px 0 0;
  max-height: calc(50dvh);
  height: var(--mobile-menu-height);
  height: max-content;
  // min-height: min(50dvh, 600px);
  .mobileMenuInner {
    padding: 3% 3% 70px 3%;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
.infoText.infoText p {
  line-height: 1.5rem;
}
.mobileOpenButtonWrapper {
  position: fixed;
  bottom: 10px;
  right: calc(6%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint('md') {
    display: none;
  }
  &.mobileMenuOpen {
    z-index: 1308;
  }
  .closeText {
    font-size: 16px;
    margin-right: 20px;
  }
  svg {
    height: 25px;
    width: 25px;
  }
}
.mobileOpenButton {
  height: 50px;
  width: 50px;
  border: 1px solid #686868;
  background: linear-gradient(0deg, #3c3c3c, #222);
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.scrollContainer {
  padding: 25px 25px 25px 25px;
  max-height: calc(100dvh - 180px);
  min-height: calc(100dvh - 180px);
  padding-top: 12.5px;
  overflow-y: scroll;
  overflow-x: hidden;
  &.selectPage {
    max-height: calc(var(--container-height) - var(--header-height) - 58px); //calc(100dvh - 170px);
    min-height: unset;
  }
  @include breakpoint(md) {
    &.selectPage {
      max-height: 70dvh;
      min-height: unset;
    }
    max-height: 70dvh;
    min-height: 70dvh;
  }
}

.titleWrapper {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 12.5px;
}

.qrImage {
  max-width: min(500px, 80vw);
  height: auto;
}
.headsetIconWrapper {
  .notificationIcon {
    --icon-size: 16px;
    height: var(--icon-size);
    width: var(--icon-size);
    top: calc(var(--icon-size) * -0.5);
    right: calc(var(--icon-size) * -0.5);
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 0 4px 0px rgba(0, 0, 0, 0.3);
  }
}
